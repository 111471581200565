import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import Logo from '../Logo/Logo.react';
import ContactBar from '../ContactBar/ContactBar.react';
import MainMenu from '../MainMenu/MainMenu.react';
// import Logo from '../../img/tbr-logo.svg';

export const StyledFooter = styled.div`
	${tw`
    mx-auto md:mt-8
    md:grid grid-rows-2 grid-cols-6 grid-flow-col gap-4
    lg:max-w-screen-lg
  `}
`;

export const LogoHolder = styled.div`
	${tw`
    md:row-span-2
    md:col-span-2
    mx-1/4 md:m-2
    p-8
  `}
`;

export const FooterLogo = styled(Logo)`
	${tw`

  `}
`;

export const FooterContactBar = styled(ContactBar)`
	${tw`
    col-span-4
    row-span-1
    mx-auto
    pb-6
      `}
`;

export const FooterNav = styled(MainMenu)`
	${tw`
    col-span-4
    mx-auto
    w-full

    pt-12
  `}
`;
