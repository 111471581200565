import React from 'react';
import { StyledFooter, FooterContactBar, FooterLogo, FooterNav, LogoHolder } from './Footer.styles';

const Footer = ({ className, menu, page, instaFeed, ...props }) => (
	<>
		<StyledFooter className={className}>
			<LogoHolder>
				<FooterLogo className={className} />
			</LogoHolder>

			<FooterNav
				className={className}
				footer={true}
				mobile={false}
				menu={menu}
				page={page}
				menuName={'footer'}
			/>
			<FooterContactBar className={className} />
		</StyledFooter>
	</>
);

export default Footer;
